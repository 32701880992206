<template>
  <div
    class="aggregator-products-wrapper"
    v-if="$config.whitelabel.features.aggregatorEnabled  && $config.whitelabel.features.generateNewILO && this.companyData && this.countryList.includes(companyData.registeredAddressCountry)"
  >
    <ProductList
      :class="'product-list'"
      title="Instant Loan Offers"
      :subtitle="setSubtitle"
      :products="sortedLoanProducts"
      :locked="locked"
      :loading="loading"
      :error="providerErrors"
      :seeMore="false"
      :allProvidersResponded="allProvidersResponded"
      @view-product="viewProduct"
    >
      <template v-slot:product-list-locked>
        <AggregatorLocked :locked="locked" />
      </template>
      <template v-slot:product-list-loading>
        <AggregatorLoading v-if="!allProvidersResponded" />
      </template>
      <template v-slot:product-list-empty>
        <AggregatorStatusCard v-if="allProvidersResponded" />
      </template>
      <template v-slot:product-list-error>
        <AggregatorStatusCard :error="providerErrors" />
      </template>
      <template v-slot:product-detail="{ product }">
        <AggregatorProduct :product="productDetailedDisplay" v-if="product" :companyId="companyId" :currentOpportunityId="currentOpportunityId" />
      </template>
    </ProductList>
    <div v-if="$config.whitelabel.features.generateNewILO">
      <div v-if="allProvidersResponded && !loading">
        <div class="card">
          <div>Did you enter incorrect information, or have your circumstances changed?</div>
          <div>
            Please check your details are correct for your most recent
            <router-link class="link" :to="{ name: mostRecentCompletedGoalRoute || 'goals' }">funding goal</router-link> and check your
            <router-link class="link" :to="{ name: 'data-room', params: { step: 'financials' } }"> data room</router-link> to ensure your information is up to date, and then
            <router-link class="link" :to="{ name: 'aggregator-form' }">click here to request new Instant Loan Offers.</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getOpportunityDecisions, deleteCompanyOpportunities } from '@product/api/aggregator'
import ProductList from '@product/components/products/ProductList.vue'
import AggregatorProduct from '@product/components/aggregator/AggregatorProduct.vue'
import { amplitudeEvents } from '@/utils/constants'
import { mapGetters, mapActions } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'
import notifies from '@/mixins/notifies'
import { getCompany } from '@/api/company'
import { getAllForms, getFormStructure } from '@/api/goals/index'
import mapsFields from '@/mixins/mapsFields'
import AggregatorLocked from './AggregatorLocked'
import AggregatorLoading from './AggregatorLoading.vue'
import AggregatorStatusCard from './AggregatorStatusCard.vue'
import { aggregatorCompanyOpportunitiesRoutine, aggregatorGetters, aggregatorOpportunityIdRoutine } from '@product/store/modules/aggregator/routines'
import { authGetters } from '@/store/modules/auth/routines'

export default {
  components: {
    AggregatorProduct,
    ProductList,
    AggregatorLocked,
    AggregatorLoading,
    AggregatorStatusCard
  },
  mixins: [notifies, mapsFields],
  async mounted() {
    if (this.$config.whitelabel.features.aggregatorEnabled) {
      try {
        const { data } = await getCompany(this.companyId)
        this.companyData = data

        const {
          data: { opportunities }
        } = await this.setCompanyOpportunities(this.companyId)

        this.opportunities = opportunities.filter(opportunity => new Date('2021-07-02T13:15:00.0000000').getTime() <= new Date(opportunity.dateCreated).getTime()) // filter out any opportunities before the date when the business model changed, needs to remain
        this.sortedOpportunities = this.opportunities.sort(
          (latestOpportunity, oldestOpportunity) => new Date(oldestOpportunity.dateCreated).getTime() - new Date(latestOpportunity.dateCreated).getTime()
        )
        if (opportunities.length) {
          this.currentOpportunityId = this.sortedOpportunities[0].opportunityId
          await this.getProviderDecisions(this.companyId, this.currentOpportunityId)
        } else {
          this.loading = false
        }
        this.checkIfUserFilledOldForms()
      } catch (error) {
        this.providerErrors = error
        this.loading = false
      }
    }
  },
  data() {
    return {
      allProvidersResponded: false,
      companyData: {},
      companyOpportunities: [],
      providerResponses: [],
      providerErrors: null,
      products: [],
      loading: true,
      locked: true,
      product: null,
      limit: 150,
      currentOpportunityId: null,
      sortedOpportunities: null,
      opportunities: null,
      statusQualified: null,
      countryList: ['United Kingdom', 'UnitedKingdom', 'Denmark', 'Ireland'],
      hasDecision: null,
      submittedFields: null,
      mostRecentCompletedGoalRoute: null,
      lastYearsTurnover: null
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID,
      aggregatorOpportunityId: aggregatorGetters.AGGREGATOR_OPPORTUNITY_ID,
      user: authGetters.USER
    }),
    productDetailedDisplay() {
      // attach a displayDetails boolean for the product details page
      return this.product ? { ...this.product, displayDetails: true } : this.product
    },
    loanProducts() {
      const productList = []
      this.providerResponses.forEach(product => {
        if (product?.decision && product?.status !== 'Qualified') {
          ;[...product.decision.businessLoanOffers, ...product.decision.cashAdvanceOffers, ...product.decision.revolvingCreditFacilityOffers].forEach(productData => {
            const hasDecision = {
              providerName: this.providerName(product),
              loanAmount: productData.loanAmount,
              interestRateApr: productData.interestRateApr * 100 || null,
              applicationLink: productData.applicationLink,
              approvalValidUntil: product.decision.validUntil,
              loanDurationInMonths: productData.loanDurationInMonths,
              cashAdvance: productData.fundedAmount,
              factorRate: productData.loanAmount / productData.fundedAmount || null,
              repaymentSweep: productData.sweepPercentage,
              type: this.getProductType(product),
              actionable: this.getProductType(product) !== 'mir',
              aggregatorProduct: true,
              status: product.status,
              isDeclined: this.isDeclined(product),
              applicationId: product.id,
              totalRepaid: productData.totalRepaid,
              loanTerms: productData.loanTerms,
              aprEquivalent: productData.aprEquivalent * 100,
              dateModified: product.dateModified,
              totalInterestPaid: productData.totalInterestPaid
            }
            productList.push(hasDecision)
          })
        } else if (product?.status === 'InformationRequested' || product?.status === 'Qualified') {
          const informationRequested = {
            providerName: this.providerName(product),
            type: this.getProductType(product),
            actionable: this.getProductType(product) !== 'mir',
            aggregatorProduct: true,
            status: product.status,
            applicationId: product.id,
            dateModified: product.dateModified,
            lastYearsTurnover: this.lastYearsTurnover
          }
          productList.push(informationRequested)
        }
      })
      return productList
    },
    sortedLoanProducts() {
      // Offer > Matched > InformationRequested > ClosedLost. If status the same then new dateModified
      return [...this.loanProducts].sort((a, b) => {
        const aTotal = this.checkStatusPriority(a)
        const bTotal = this.checkStatusPriority(b)
        const datePriority = new Date(b.dateModified).getTime() > new Date(a.dateModified).getTime() ? 1 : -1
        return bTotal - aTotal + datePriority
      })
    },
    setSubtitle() {
      return this.providerResponses && this.providerResponses.length
        ? 'We’ve got you our best offers from our smart lending network.'
        : 'With a few more details we can get you an offer in minutes.'
    }
  },
  watch: {
    providerErrors(errors) {
      if (errors) {
        this.$ma.trackEvent(amplitudeEvents.AggregatorApi.INSTANT_OFFER_ERROR)
        this.notifyError()
      }
    }
  },
  methods: {
    ...mapActions({
      setCompanyOpportunities: aggregatorCompanyOpportunitiesRoutine.TRIGGER,
      setAggregatorOpportunityId: aggregatorOpportunityIdRoutine.TRIGGER
    }),
    async checkIfUserFilledOldForms() {
      if (this.user?.goalsCompleted?.length > 0) {
        const mostRecentFilledFormId = this.user?.goalsCompleted[this.user?.goalsCompleted.length - 1]?.formId
        if (mostRecentFilledFormId) {
          const { data } = await getAllForms()
          this.mostRecentCompletedGoalRoute = data?.find(form => form.formId === mostRecentFilledFormId)?.route || null
          this.lastFilledFormId = data?.find(form => form.formId === mostRecentFilledFormId)
          if (this.lastFilledFormId) {
            const res = await getFormStructure(this.companyId, this.lastFilledFormId?.formId)
            const turnOverFields = ['borrowAmount', 'totalTurnover', 'turnoverLastYear', 'fundingAmount', 'revenueInLast12Months'] // each form has different value we need to check them
            const getTurnoverField = res?.data?.formSections?.[0].formFields.find(field => turnOverFields.includes(field.name))
            this.lastYearsTurnover = getTurnoverField.value
          }
        }
      }
    },
    checkStatusPriority(product) {
      switch (product.status) {
        case 'Offer':
          return 12
        case 'Matched':
          return 9
        case 'InformationRequested':
          return 6
        case 'ClosedLost':
          return 3
        default:
          return 0
      }
    },
    getProductType(products) {
      if (products?.decision?.businessLoanOffers?.length) {
        return 'business-loan-offer'
      } else if (products?.decision?.cashAdvanceOffers?.length) {
        return 'mca'
      } else if (products?.decision?.revolvingCreditFacilityOffers?.length) {
        return 'rcf'
      } else {
        return 'mir'
      }
    },
    isDeclined(products) {
      return [products.decision && products.decision.verdict].includes('Declined')
    },
    providerName(products) {
      const providerName = {
        youlend: 'YouLend',
        iwoca: 'iwoca',
        fibr: 'FIBR',
        retailcapital: 'Recap'
      }
      return providerName[products.productName]
    },
    async isAllProvidersResponded(opportunityId) {
      this.setAggregatorOpportunityId(opportunityId)
      this.loading = false
      this.$ma.trackEvent({
        eventType: amplitudeEvents.Matches.INSTANT_OFFER_CTA,
        eventProperties: {
          productsFound: this.loanProducts.length,
          ...this.providerResponses
        }
      })
      if (this.submittedFields) {
        this.loading = false
        window.localStorage.removeItem('submittedFields')
        this.$ma.trackEvent({
          eventType: amplitudeEvents.AggregatorApi.INSTANT_OFFER_RECEIVED,
          eventProperties: {
            productsFound: this.loanProducts.length
          }
        })
      }
    },
    async getDecisions(companyId, opportunityId) {
      this.submittedFields = window.localStorage.getItem('submittedFields')
      this.hasDecision = this.providerResponses.filter(product => product.decision !== null)
      const previousOppId = this.aggregatorOpportunityId // getters
      this.allProvidersResponded = this.providerResponses.map(product => Boolean(product.decision) || product.status === 'InformationRequested').every(decision => decision)
      this.statusQualified = this.providerResponses.filter(product => product.status === 'Qualified')

      if (this.allProvidersResponded) {
        await this.isAllProvidersResponded(opportunityId)
      } else if (this.providerResponses.length && this.statusQualified && this.submittedFields) {
        this.loading = false
        this.limit--
        await this.getProviderDecisions(companyId, opportunityId)
      } else if (this.providerResponses.length && this.hasDecision.length) {
        this.loading = false
        this.limit--
        await this.getProviderDecisions(companyId, opportunityId)
      } else if (previousOppId) {
        this.loading = false
        await this.getProviderDecisions(companyId, previousOppId)
      } else if (this.providerResponses.length && !this.hasDecision.length && !this.submittedFields) {
        this.locked = true
        this.loading = false
        deleteCompanyOpportunities(companyId, opportunityId)
      } else {
        this.limit--
        await this.getProviderDecisions(companyId, opportunityId)
      }
    },
    async getProviderResponses(companyId, opportunityId) {
      try {
        if (this.limit > 0) {
          setTimeout(async () => {
            await this.getDecisions(companyId, opportunityId)
          }, 1000)
        } else if (this.limit === 0) {
          return
        }
      } catch (error) {
        this.providerErrors = error
        this.loading = false
      }
    },
    async getProviderDecisions(companyId, opportunityId) {
      this.locked = false
      try {
        const {
          data: { applications }
        } = await getOpportunityDecisions(companyId, opportunityId)
        this.providerResponses = applications
        await this.getProviderResponses(companyId, opportunityId)
      } catch (error) {
        this.$ma.trackEvent(amplitudeEvents.AggregatorApi.INSTANT_OFFER_ERROR)
        this.providerErrors = error
      }
    },
    viewProduct(product) {
      this.product = product
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';
.aggregator-products-wrapper {
  width: 100%;
  .product-list {
    padding: 72px 0 0 0;
    @media only screen and (max-width: $breakpoint-xs-max) {
      padding: 5px 0 0 0;
    }
  }
  /deep/.details-page-content {
    padding-right: 500px;
  }
  /deep/.product-list-container .product-subtitle {
    margin-bottom: 30px;
  }
  .data-consent {
    margin: 65px 0 0;
    @media only screen and (max-width: $breakpoint-xs-max) {
      margin-bottom: 20px;
    }
  }
  /deep/.controls {
    margin-top: 0;
  }
  .card {
    border: 1px solid var(--color-secondary-50);
    box-shadow: 0px 0px 1px rgba(0, 62, 82, 0.16), 0px 1px 3px rgba(0, 62, 82, 0.16);
  }
  .link {
    margin: 0 3px;
  }
}
</style>
