<template>
  <div class="product-list-empty-wrapper">
    <span class="material-icons"> search_off </span>
    <div>
      <p>
        <span>Unfortunately, there aren’t any matches based on your information.</span>
        <button class="link" @click="openOverlayForm(currentFormName)">Click here</button> to go back and amend your search.
      </p>
      <p v-if="subtext && productType !== 'grant'">
        {{ subtext }}
      </p>
    </div>
  </div>
</template>

<script>
import { currentFormNameGetters, currentFormNameRoutine } from '@/store/modules/currentFormDetails/routines'
import { mapGetters, mapActions } from 'vuex'
import { pageOverlayGetters, pageOverlayRoutine } from '@/store/modules/pageOverlay/routines'

export default {
  data() {
    return {
      subtext: this.$dictionary?.emptyProductListSubtext || ''
    }
  },
  props: {
    productType: {
      type: String
    }
  },
  computed: {
    ...mapGetters({
      currentFormName: currentFormNameGetters.CURRENT_FORM_NAME,
      isPageOverlayOpen: pageOverlayGetters.IS_PAGE_OVERLAY_OPEN
    })
  },
  methods: {
    ...mapActions({
      setPageOverlay: pageOverlayRoutine.TRIGGER,
      setCurrentFormName: currentFormNameRoutine.TRIGGER
    }),
    openOverlayForm() {
      if (this.productType === 'grant') {
        this.setCurrentFormName('findGrant')
      }
      this.setPageOverlay(!this.isPageOverlayOpen)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.product-list-empty-wrapper {
  display: flex;
  padding: 10px 18px;
  border-bottom: 2px solid var(--color-primary-200);

  span.material-icons {
    font-size: 60px;
    margin-right: 30px;
    color: var(--color-primary-300);
  }

  p {
    font-size: 16px;

    span {
      display: block;

      a.link {
        text-decoration: none;
        font-weight: 500;
      }
    }
  }
}
</style>
