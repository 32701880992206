<template>
  <div>
    <h1>Product information</h1>
    <p>
      The YouLend Merchant Cash Advance aligns naturally and seamlessly with your cashflow. You repay a small percentage of your daily transactions from your customers until your
      merchant cash advance has been repaid. This gives you breathing room to navigate the seasonal ups and downs of running a business.
    </p>
    <p>
      This offer is subject to additional information - just fill out a few final details, confirm your financials, and they’ll provide you with their final offer. They will
      perform further soft checks to generate this final offer.
    </p>
    <h2>How it works</h2>
    <h4>Your offer</h4>
    <p>
      YouLend funding is unsecured and can be used for any business purpose. YouLend have already seen enough information to make you an indicative offer. This offer is subject to
      further checks.
    </p>
    <h4>Accepting your quick offer</h4>
    <p>
      To accept the offer, you’ll continue to YouLend’s website where they’ll ask for any additional information they need. Fill out a few final details, upload the signed
      agreement, and your funds can be with you within the week.
    </p>
    <h4>Repay as you earn</h4>
    <p>
      You repay a small percentage of each of your business transactions until your Merchant Cash Advance (MCA) has been repaid. This gives you breathing room to navigate the
      seasonal ups and downs of running a business.
    </p>
    <p>You can find out more about MCA products in our <button class="link" @click="clickKnowledgeCentre()">Knowledge Centre</button>.</p>
    <em> Note: YouLend does not lend to companies in the following industries: Used Car Sales; Adult; Gambling; Charities; Firearms. </em>
  </div>
</template>

<script>
import { amplitudeEvents } from '@/utils/constants'

export default {
  props: {
    product: {
      type: Object
    }
  },
  methods: {
    clickKnowledgeCentre() {
      window.open('https://swoopfunding.com/merchant-cash-advance/', '_blank')
      this.$ma.trackEvent({
        eventType: amplitudeEvents.AggregatorApi.CLICK_TO_KNOWLEDGE_BASE,
        eventProperties: { product: this.product.providerName, type: this.product.type }
      })
    }
  }
}
</script>