import { countriesOptions } from '@/utils/countries'
import config from '@/config/global'

export default [
  {
    type: 'input',
    name: 'firstName',
    label: 'First name',
    value: '',
    rules: 'required'
  },
  {
    type: 'input',
    name: 'lastName',
    label: 'Last name',
    value: '',
    rules: 'required'
  },
  {
    type: 'select',
    name: 'title',
    label: 'Title',
    value: '',
    options: [
      { label: 'Mr', value: 'Mr' },
      { label: 'Mrs', value: 'Mrs' },
      { label: 'Ms', value: 'Ms' },
      { label: 'Miss', value: 'Miss' },
      { label: 'Dr', value: 'Dr' },
      { label: 'Lady', value: 'Lady' },
      { label: 'Lord', value: 'Lord' },
      { label: 'Sir', value: 'Sir' }
    ],
    rules: 'required'
  },
  {
    type: 'select',
    name: 'gender',
    label: 'Gender',
    value: '',
    options: [
      {
        label: 'Male',
        value: 'Male'
      },
      {
        label: 'Female',
        value: 'Female'
      },
      {
        label: 'Prefer not to say',
        value: 'PreferNotToSay'
      },
      {
        label: 'Other',
        value: 'Other'
      }
    ],
    rules: 'required'
  },
  {
    type: 'select',
    name: 'employmentStatus',
    label: 'Employment status',
    value: '',
    options: [
      {
        label: 'Not Found',
        value: 'NotFound'
      },
      {
        label: 'Employed',
        value: 'Employed'
      },
      {
        label: 'Self Employed',
        value: 'SelfEmployed'
      },
      {
        label: 'Retired',
        value: 'Retired'
      },
      {
        label: 'Not Provided',
        value: 'NotProvided'
      },
      {
        label: 'Other',
        value: 'Other'
      },
      {
        label: 'Unemployed',
        value: 'Unemployed'
      },
      {
        label: 'Full Time',
        value: 'FullTime'
      },
      {
        label: 'Part Time',
        value: 'PartTime'
      },
      {
        label: 'Self Employed Less Than 2 Years',
        value: 'SelfEmployedLessThan2Years'
      },
      {
        label: 'Self Employed More Than 2 Years',
        value: 'SelfEmployedMoreThan2Years'
      },
      {
        label: 'Student',
        value: 'Student'
      },
      {
        label: 'Homemaker',
        value: 'Homemaker'
      },
      {
        label: 'Temporarily Employed',
        value: 'TemporarilyEmployed'
      }
    ],
    rules: 'required'
  },
  {
    type: 'input',
    dataType: 'currency',
    name: 'income',
    label: 'Annual income',
    value: '',
    icon: 'currency',
    iconPosition: 'left',
    rules: 'required'
  },
  {
    type: 'date',
    dataType: 'day',
    returnType: 'dd/mm/yyyy',
    label: 'Date of birth',
    name: 'dateOfBirth',
    value: new Date().getTime() / 1000,
    rules: 'required'
  },
  {
    type: 'input',
    dataType: 'currency',
    name: 'personalCreditLimit',
    label: 'Personal Credit Limit',
    value: '',
    icon: 'currency',
    iconPosition: 'left',
    rules: 'required'
  },
  {
    type: 'select',
    name: 'residentialStatus',
    label: 'What is your residential status?',
    value: '',
    options: [
      {
        label: 'Not Found',
        value: 'NotFound'
      },
      {
        label: 'Home Owner',
        value: 'HomeOwner'
      },
      {
        label: 'Living With Parents',
        value: 'LivingWithParents'
      },
      {
        label: 'Tenant',
        value: 'Tenant'
      },
      {
        label: 'Not Provided',
        value: 'NotProvided'
      },
      {
        label: 'Other',
        value: 'Other'
      },
      {
        label: 'Private Tenant',
        value: 'PrivateTenant'
      },
      {
        label: 'Council Tenant',
        value: 'CouncilTenant'
      },
      {
        label: 'Home Owner No Mortgage',
        value: 'HomeOwnerNoMortgage'
      }
    ],
    rules: 'required'
  },
  'Home address',
  {
    type: 'input',
    name: 'buildingNumber',
    label: 'Building number',
    value: '',
    rules: 'required'
  },
  {
    type: 'input',
    name: 'street',
    label: 'Street',
    value: '',
    rules: 'required'
  },
  {
    type: 'input',
    name: 'city',
    label: 'City / Town',
    value: '',
    rules: 'required'
  },
  {
    type: 'input',
    name: 'postcode',
    label: 'Postcode',
    rules: 'required',
    value: ''
  },
  {
    ...(
      config.whitelabel.forms.states &&
      {
        type: 'select',
        name: 'state',
        metaDataMappedFrom: 'states',
        label: 'State',
        rules: 'required',
        value: '',
        options: config.whitelabel.forms.stateOptions
      }
    )
  },
  {
    ...(
      config.whitelabel.forms.provinces &&
      {
        type: 'select',
        name: 'state',
        metaDataMappedFrom: 'states',
        label: 'Province',
        rules: 'required',
        value: '',
        options: config.whitelabel.forms.provinceOptions
      }
    )
  },
  {
    type: 'select',
    name: 'country',
    label: 'Country',
    rules: 'required',
    options: countriesOptions,
    value: ''
  }
]
