<template>
  <div>
    <div class="loading-tile">
      <div class="product-image"></div>
      <div class="product-information">
        <div class="information-box">
          <div class="information-box-tile"></div>
          <div class="information-box-tile"></div>
          <div class="information-box-tile"></div>
        </div>
        <div class="information-box"></div>
        <div class="information-box"></div>
        <div class="see-more"></div>
      </div>
    </div>
    <div class="loading-tile">
      <div class="product-image"></div>
      <div class="product-information">
        <div class="information-box">
          <div class="information-box-tile"></div>
          <div class="information-box-tile"></div>
          <div class="information-box-tile"></div>
        </div>
        <div class="information-box"></div>
        <div class="information-box"></div>
        <div class="see-more"></div>
      </div>
    </div>
    <div class="loading-tile">
      <div class="product-image"></div>
      <div class="product-information">
        <div class="information-box">
          <div class="information-box-tile"></div>
          <div class="information-box-tile"></div>
          <div class="information-box-tile"></div>
        </div>
        <div class="information-box"></div>
        <div class="information-box"></div>
        <div class="see-more"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables';
.loading-tile {
  margin: 12px 0;
  height: 118px;
  border: 1px solid var(--color-primary-100);
  border-radius: 8px;
  display: flex;
  position: relative;
  background-color: $color-white;

  .product-image {
    height: 100%;
    width: 285px;
    border-right: 1px solid var(--color-primary-100);
    display: flex;
    justify-content: center;
    align-items: center;
    &::after {
      border-radius: 8px;
      content: '';
      animation: 1.5s infinite blink;
      background: var(--color-primary-100);
      width: 70%;
      height: 60%;
    }
  }
  .product-information {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 20px;
    .information-box {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .information-box-tile {
        border-radius: 8px;
        content: '';
        animation: 1.5s infinite blink;
        background-color: var(--color-primary-100);
        width: 80%;
        height: 30px;
        margin: 4px;
      }
    }
    .see-more {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &::after {
        margin-top: 4px;
        border-radius: 16px;
        content: '';
        animation: 1.5s infinite blink;
        background: var(--color-primary-100);
        width: 80%;
        height: 30%;
      }
    }
  }
}

@keyframes blink {
  50% {
    background-color: var(--color-primary-50);
  }
}
</style>
