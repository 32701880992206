<template>
  <div class="locked-wrapper">
    <AggregatorLockedTile />
    <div class="locked-card">
      <h1>Get an offer instantly</h1>
      <p>
        You’re only one step away from receiving <br />
        offer of up to £1,000,000
      </p>
      <div class="steps">
        <div class="step">
          <img :src="require('@/assets/images/products/b-current-account.svg')" alt="step 1 icon"/>
          <span>1</span>
          Tell us a bit more about your business and funding requirements
        </div>
        <div class="step">
          <img :src="require('@/assets/images/products/loans.svg')" alt="step 2 icon"/>
          <span>2</span>
          We’ll share your information and fetch indicative offers from our funding providers
        </div>
        <div class="step">
          <img :src="require('@/assets/images/products/start-up.svg')" alt="step 3 icon"/>
          <span>3</span>
          Review your options and accept the product that works best for your business
        </div>
      </div>
      <router-link :to="{ name: 'aggregator-form' }" class="btn btn-primary">Find offers</router-link>
      <p>Don’t worry - receiving offers at this stage won’t affect your credit score!</p>
    </div>
  </div>
</template>

<script>
import AggregatorLockedTile from './AggregatorLockedTile'
export default {
  components: { AggregatorLockedTile }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables';
.locked-wrapper {
  position: relative;
  padding: 0;
  .locked-card {
    width: 600px;
    padding: 24px;
    height: 350px;
    position: absolute;
    background-color: $color-white;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 8px;
    border: 1px solid var(--color-primary-100);
    text-align: center;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.04), 0 8px 16px 0 rgba(0, 0, 0, 0.06);
    @media only screen and (max-width: 600px) {
      width: 100%;
      bottom: auto;
      height: auto;
      padding: 8px;
    }
    .left-align {
      text-align: left;
      p {
        margin: 20px 0;
      }
    }
    h1 {
      margin-bottom: 0;
    }
    p {
      margin-bottom: 14px;
      font-size: 14px;
    }
    .btn {
      margin: 20px 0;
    }
    .link {
      margin-left: 20px;
    }
    .steps {
      display: flex;
      justify-content: space-around;
      .step {
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: 12px;
        line-height: 16px;
        max-width: 150px;
        img {
          width: 54px;
          height: 54px;
          align-self: center;
        }
        span {
          background-color: $color-white;
          margin-top: -10px;
          font-weight: 900;
          font-size: 10px;
          border: 1px solid #e7ebef;
          width: 18px;
          height: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          align-content: center;
          align-self: center;
          border-radius: 10px;
          color: var(--color-primary-400);
        }
      }
    }
  }
  .btn-primary {
    width: 200px;
  }
}
</style>
