<template>
  <div class="filter-bar-container">
    <div class="sort-container">
      <em :class="[{ hidden: !hideProductListContainer }, 'icon material-icons']" @click="toggleGroupProducts()">arrow_back</em>
      <span :class="[{ hidden: !hideProductListContainer }, 'results']"> {{ filteredProducts.length }} results </span>
      <div class="sort-menu" @click="hideMenu()">
        <em class="icon material-icons">sort</em>
        <div>Sort by</div>
      </div>
      <button :class="[{ hidden: hideSortButtons, active: isButtonActive === 'amount' }, 'sort-btn btn-transparent']" tabindex="3" @click="sortByAmount()">
        <div class="sort-title">Amount</div>
        <div class="divider"></div>
        <em :class="[{ rotate: sortToggleAmount }, 'icon material-icons']">arrow_downward</em>
      </button>
      <button :class="[{ hidden: hideSortButtons, active: isButtonActive === 'interest rate' }, 'sort-btn btn-transparent']" tabindex="4" @click="sortByInterestRate()">
        <div class="sort-title">Interest rate</div>
        <div class="divider"></div>
        <em :class="[{ rotate: sortToggleInterestRate }, 'icon material-icons']">arrow_downward</em>
      </button>
      <button :class="[{ hidden: hideSortButtons, active: isButtonActive === 'decision time' }, 'sort-btn btn-transparent']" tabindex="5" @click="sortByDecisionTime()">
        <div class="sort-title">Decision time</div>
        <div class="divider"></div>
        <em :class="[{ rotate: sortToggleDecisionTime }, 'icon material-icons']">arrow_downward</em>
      </button>
    </div>
    <div class="dropdown">
      <em class="icon material-icons">filter_center_focus</em>
      <select name="status" tabindex="5" v-model="selectedSubcategoryName">
        <option value="all" v-if="!hideProductListContainer">Product type</option>
        <option v-for="productSubcategory in productSubcategories" :value="productSubcategory" :key="productSubcategory">
          {{ productSubcategory }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { amplitudeEvents } from '@/utils/constants'
import { mapGetters, mapActions } from 'vuex'
import { currentFormNameGetters } from '@/store/modules/currentFormDetails/routines'
import { sortByAmountRoutine, sortByInterestRateRoutine, sortByDecisionTimeRoutine, sortByProductTypeRoutine } from '@/store/modules/sortBar/routines'

export default {
  name: 'LoanMatchesFilterBar',
  data() {
    return {
      sortToggleAmount: false,
      sortToggleInterestRate: false,
      sortToggleDecisionTime: false,
      hideSortButtons: false,
      isButtonActive: '',
      sortedProductList: this.sortedProducts || [],
      selectedSubcategoryName: this.selectedSubcategory || ''
    }
  },
  props: {
    productSubcategories: {
      type: Array
    },
    filteredProducts: {
      type: Array
    },
    sortedProducts: {
      type: Array
    },
    selectedSubcategory: {
      type: String
    },
    hideProductListContainer: {
      type: Boolean
    }
  },
  methods: {
    ...mapActions({
      setSortByAmount: sortByAmountRoutine.TRIGGER,
      setSortByInterestRate: sortByInterestRateRoutine.TRIGGER,
      setSortByDecisionTime: sortByDecisionTimeRoutine.TRIGGER,
      setSortByProductType: sortByProductTypeRoutine.TRIGGER
    }),
    hideMenu() {
      if (!this.detectMobile) {
        return !this.hideSortButtons
      } else {
        this.hideSortButtons = !this.hideSortButtons
      }
    },
    sortByAmount() {
      this.isButtonActive = 'amount'
      this.sortToggleAmount ? this.setSortByAmount('Ascending') : this.setSortByAmount('Descending')
      this.$ma.trackEvent({ eventType: amplitudeEvents.Matches.SORT_BY_LOAN_AMOUNT })
      this.sortToggleAmount = !this.sortToggleAmount
      this.sortedProducts = [...this.sortedProducts].sort((a, b) => (this.sortToggleAmount ? b.minSize - a.minSize : a.minSize - b.minSize))
    },
    sortByInterestRate() {
      this.isButtonActive = 'interest rate'
      this.sortToggleInterestRate ? this.setSortByInterestRate('Ascending') : this.setSortByInterestRate('Descending')
      this.$ma.trackEvent({ eventType: amplitudeEvents.Matches.SORT_BY_LOAN_INTEREST_RATE })
      this.sortToggleInterestRate = !this.sortToggleInterestRate
      this.sortedProducts = [...this.sortedProducts].sort((a, b) => (this.sortToggleInterestRate ? b.aprMin - a.aprMin : a.aprMin - b.aprMin))
    },
    sortByDecisionTime() {
      this.isButtonActive = 'decision time'
      this.sortToggleDecisionTime ? this.setSortByDecisionTime('Ascending') : this.setSortByDecisionTime('Descending')
      this.$ma.trackEvent({ eventType: amplitudeEvents.Matches.SORT_BY_LOAN_DECISION_TIME })
      this.sortToggleDecisionTime = !this.sortToggleDecisionTime
      this.sortedProducts = [...this.sortedProducts].sort((a, b) => (this.sortToggleDecisionTime ? b.speed - a.speed : a.speed - b.speed))
    }
  },
  computed: {
    ...mapGetters({ currentFormName: currentFormNameGetters.CURRENT_FORM_NAME }),
    detectMobile() {
      return window.innerWidth <= 800
    }
  },
  watch: {
    selectedSubcategoryName(value) {
      this.selectedSubcategoryName = value
      this.setSortByProductType(value)
      this.$emit('refetch-selectedSubcategory', value)
    },
    sortedProducts(value) {
      this.sortedProduct = value
      this.$emit('refetch-sortedProducts', value)
    },
    currentFormName(selectedFormName) {
      selectedFormName && (this.selectedSubcategoryName = 'all')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

$breakpoint-sm-max-include-sidebar: $breakpoint-sm-max + $sidebar-width;

.filter-bar-container {
  margin-top: 20px;
  border: 1px solid var(--color-secondary-50);
  border-radius: 8px;
  padding: 20px 10px;
  display: flex;
  flex-direction: row;
  font-size: 13px;
  justify-content: space-between;
  background-color: $color-white;
  box-shadow: 0px 0px 1px rgba(0, 62, 82, 0.16), 0px 1px 3px rgba(0, 62, 82, 0.16);

  @media only screen and (max-width: $breakpoint-sm-max-include-sidebar) {
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }

  .sort-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media only screen and (max-width: $breakpoint-sm-max-include-sidebar) {
      flex-direction: row;
      align-items: flex-start;
    }
    @media only screen and (max-width: $breakpoint-sm) {
      flex-direction: column;
    }

    .results {
      padding: 0 20px;
    }

    .sort-menu {
      display: flex;
      flex-direction: row;
    }

    .sort-btn {
      cursor: pointer;
      margin-left: 30px;
      border: 1px solid var(--color-secondary-50);
      border-radius: 8px;
      display: flex;

      @media only screen and (max-width: $breakpoint-sm-max-include-sidebar) {
        margin: 0px 10px;
      }

      &:hover,
      &:active,
      &.active {
        background-color: var(--color-secondary-500);
        color: $color-white;
      }

      .sort-title {
        white-space: nowrap;
        margin: 5px;
        @media only screen and (max-width: $breakpoint-sm-max-include-sidebar) {
          width: 80px;
        }
      }
      .divider {
        border-right: 1px solid var(--color-secondary-50);
        padding: 12px 0;
        background-color: transparent;
      }
    }

    .hidden {
      display: none;
    }
  }

  .material-icons {
    margin: 0 5px;
    vertical-align: middle;
    font-size: 20px;
    transition: all 0.4s ease;
    @media only screen and (max-width: $breakpoint-sm-max-include-sidebar) {
      margin: 0;
    }
  }
  .rotate {
    transform: rotate(180deg);
  }

  .dropdown {
    display: flex;
    flex-direction: row;
    align-items: center;
    @media only screen and (max-width: $breakpoint-sm-max-include-sidebar) {
      margin-top: 30px;
    }
    select {
      width: 200px;
      font-size: 14px;
      padding: 0;
      border: none;

      @media only screen and (max-width: $breakpoint-sm-max-include-sidebar) {
        height: auto;
        padding: 0;
        font-size: 13px;
        white-space: nowrap;
      }
    }
  }
}
</style>
