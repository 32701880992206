<template>
  <div class="matches-header">
    <!-- swoop assistant -->
    <transition name="fade">
      <div v-if="!isDismissed" class="assistant" data-cy="assistant">
        <img
          v-if="detectMobile"
          :alt="`${swoopAssistantSlideListMobile[countryCode][currentSlideIndex]} image `"
          :src="require(`@product/assets/images/swoopAssistant/${countryCode}/mobile/swoop-assistant-${swoopAssistantSlideListMobile[countryCode][currentSlideIndex].img}.png`)"
          width="100%"
          height="auto"
        />
        <img
          v-else
          :alt="`${swoopAssistantSlideListDesktop[countryCode][currentSlideIndex]} image `"
          :src="require(`@product/assets/images/swoopAssistant/${countryCode}/desktop/swoop-assistant-${swoopAssistantSlideListDesktop[countryCode][currentSlideIndex].img}.png`)"
          width="100%"
          height="auto"
        />
        <div class="buttons">
          <button class="btn btn-primary close" tabindex="2" @keydown.esc="toggleAssistant()" @click="toggleAssistant()" data-cy="button-x">X</button>
          <button class="btn btn-primary prev" tabindex="4" @click="prev()" data-cy="button-prev">{{ backButtonLabel }}</button>
          <button class="btn btn-primary next" tabindex="3" @click="next()" data-cy="button-next">{{ nextButtonLabel }}</button>
        </div>
      </div>
    </transition>
    <div>
      <button
        v-if="$config.whitelabel.features.swoopAssistantOverlayEnabled"
        tabindex="1"
        @keyup.left="prev()"
        @keyup.right="next()"
        @keydown.esc="toggleAssistant()"
        @click="openAssistant()"
        class="btn-white"
      >
        Assistant<em class="material-icons">assistant</em>
      </button>
      <h1 class="matches-title">Loan Funding</h1>
      <p>Check out your current options for loan funding</p>
      <p class="matches-subtitle" v-if="showSubtitle">
        To increase accuracy and speed of our product matches, you can
        <router-link tabindex="2" class="link bold" :to="{ name: 'integrations' }">
          integrate your {{ getBankOrAccountancyText() }} here, to save time on your application.
        </router-link>
      </p>
    </div>
    <ContactBanner
      :icon="$dictionary.matchesContactBanner.icon"
      :actionText="$dictionary.matchesContactBanner.actionText"
      :name="$dictionary.matchesContactBanner.name"
      :phoneNumber="$dictionary.matchesContactBanner.phoneNumber"
      :email="$dictionary.matchesContactBanner.email"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ContactBanner from '@/components/ContactBanner'
import { swoopAssistantGetters } from '@/store/modules/swoopAssistant/routines'
import config from '@/config/global'
import { amplitudeEvents } from '@/utils/constants'

export default {
  name: 'LoanMatchesHeader',
  components: {
    ContactBanner
  },
  data() {
    return {
      showSubtitle: config.whitelabel.features.integrationsEnabled && (config.whitelabel.features.linkBankAccount || config.whitelabel.features.linkAccountancy),
      currentSlideIndex: 0,
      swoopAssistantSlideListDesktop: {
        GB: [{ img: 'welcome' }, { img: 'filter' }, { img: 'product-tiles' }, { img: 'ilo' }],
        CA: [{ img: 'welcome' }, { img: 'filter' }, { img: 'product-tiles' }],
        AU: [{ img: 'welcome' }, { img: 'filter' }, { img: 'product-tiles' }]
      },
      swoopAssistantSlideListMobile: {
        GB: [{ img: 'welcome-mobile' }, { img: 'filter-mobile' }, { img: 'product-tiles-mobile' }, { img: 'ilo-mobile' }],
        CA: [{ img: 'welcome-mobile' }, { img: 'filter-mobile' }, { img: 'product-tiles-mobile' }],
        AU: [{ img: 'welcome-mobile' }, { img: 'filter-mobile' }, { img: 'product-tiles-mobile' }]
      },
      isDismissed: true,
      countryCode: this.$config.whitelabel.country.defaultCountryCode2
    }
  },
  methods: {
    getBankOrAccountancyText() {
      if (config.whitelabel.features.linkBankAccount && !config.whitelabel.features.linkAccountancy) return 'bank'
      if (!config.whitelabel.features.linkBankAccount && config.whitelabel.features.linkAccountancy) return 'accountancy software'
      return 'bank or accountancy software'
    },
    openAssistant() {
      this.currentSlideIndex = 0
      this.currentSlideIndex === 0 &&
        this.$ma.trackEvent({
          eventType: amplitudeEvents.Matches.CLICK_SWOOP_ASSISTANT_BUTTON
        })
      this.toggleAssistant()
    },
    toggleAssistant() {
      if (config.whitelabel.features.swoopAssistantOverlayEnabled) {
        this.isDismissed = !this.isDismissed
      }
    },
    next() {
      if (this.currentSlideIndex === this.assistantSlideList.length - 1) {
        this.toggleAssistant()
      }
      this.nextButtonLabel === 'Lets do it' &&
        this.$ma.trackEvent({
          eventType: amplitudeEvents.Matches.SWOOP_ASSISTANT_SHOW
        })
      this.currentSlideIndex = this.currentSlideIndex + 1
    },
    prev() {
      if (this.currentSlideIndex === 0) {
        this.$ma.trackEvent({
          eventType: amplitudeEvents.Matches.SWOOP_ASSISTANT_DONT_SHOW
        })
        this.toggleAssistant()
      }
      this.currentSlideIndex = this.currentSlideIndex - 1
    }
  },
  computed: {
    ...mapGetters({
      assistant: swoopAssistantGetters.SWOOP_ASSISTANT
    }),
    assistantSlideList() {
      if (this.detectMobile) {
        return this.isIreland ? this.swoopAssistantSlideListMobile[this.countryCode].slice(0, -1) : this.swoopAssistantSlideListMobile[this.countryCode]
      } else {
        return this.isIreland ? this.swoopAssistantSlideListDesktop[this.countryCode].slice(0, -1) : this.swoopAssistantSlideListDesktop[this.countryCode]
      }
    },
    isIreland() {
      return window.WHITE_LABEL_STYLES?.subdomain === 'ireland'
    },
    detectMobile() {
      return window.innerWidth <= 800
    },
    backButtonLabel() {
      if (this.currentSlideIndex === 0) {
        return 'Not now'
      } else {
        return 'Back'
      }
    },
    nextButtonLabel() {
      if (this.currentSlideIndex === 0) {
        return 'Lets do it'
      } else if (this.currentSlideIndex === this.assistantSlideList.length - 1) {
        return 'Done'
      }
      return 'Next'
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.assistant {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 990;
  background-color: var(--color-primary-500);
  backdrop-filter: blur(3px);
  transition: opacity 0.2s ease-in-out;

  img {
    opacity: 1;
  }

  .buttons {
    .btn {
      border: 1px solid white;
    }
    .prev {
      position: fixed;
      top: calc(100% - 170px);
      left: 200px;
      @media only screen and (max-width: $breakpoint-sm-max) {
        left: 20px;
        top: calc(100% - 60px);
      }
    }
    .next {
      position: fixed;
      top: calc(100% - 170px);
      right: 200px;
      @media only screen and (max-width: $breakpoint-sm-max) {
        right: 20px;
        top: calc(100% - 60px);
      }
    }
    .close {
      position: fixed;
      top: 100px;
      right: 200px;
      @media only screen and (max-width: $breakpoint-sm-max) {
        right: 30px;
        top: 50px;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
