<template>
  <div class="steps">
    <div class="step" v-for="(step, index) in steps" :key="step.label" :class="{ 'step-disabled': step.disabled, 'step-complete': step.complete, 'step-editable': step.editable }" @click="selectStep(step)">
      <span class="step-number">{{ index + 1 }}</span>
      {{ step.label }}
      <span class="icon material-icons">{{ step.complete ? 'check' : 'add' }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductSteps',
  props: {
    steps: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    selectStep(step) {
      if (step.editable || !step.complete) {
        this.$emit('select-step', step)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.steps {
  display: grid;
  grid-template-columns: repeat(auto-fill, 212px);
  gap: 16px;

  .step {
    position: relative;
    width: 100%;
    height: 54px;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    padding-left: 44px;
    cursor: pointer;
    padding-right: 54px;
    border: 1px solid var(--color-secondary-50);

    @media only screen and (max-width: 1100px) {
      font-size: 12px;
    }

    @media only screen and (max-width: 850px) {
      width: 100%;
      font-size: 14px;
      margin: 8px 0;
    }

    &.step-complete {
      background-color: var(--color-secondary-500);
      color: $color-white;
      cursor: auto;

      span.material-icons {
        border: 1px solid $color-white;
        background-color: transparent;
      }
    }

    &.step-editable {
      cursor: pointer;
    }

    span.step-number {
      position: absolute;
      font-size: 24px;
      left: 20px;

      @media only screen and (max-width: 1300px) {
        font-size: 16px;
      }
    }

    span.material-icons {
      width: 35px;
      height: 35px;
      border-radius: 18px;
      display: flex;
      justify-content: center;
      font-size: 24px;
      background-color: var(--color-primary-500);
      color: $color-white;
      right: 20px;
      position: absolute;
      align-items: center;
      transition: all 0.3s;

      @media only screen and (max-width: 1300px) {
        font-size: 16px;
        width: 20px;
        height: 20px;
      }
    }
  }
  .step-disabled {
    background-color: var(--color-neutral-300);
  }
}
</style>
