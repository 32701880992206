<template>
  <div class="component-wrapper">
    <h1>Director Information</h1>
    <template v-if="!formOpen">
      <p>
        To maximise succes with your application, we need to know that someone with more than 25% ownership of the company is making the application.
      </p>
      <template v-if="directors.length">
        <p>
          If you are a director yourself, or are making this application on behalf of a director, please select a name from the list below.
        </p>
        <div
          v-for="director in directors" :key="director.ownerId"
          class="director"
          @click="openForm(director)"
        >
          {{ director.firstName }} {{ director.lastName }}
        </div>
        <p>
          If you can't find the name you're looking for or you'd like to add a name of a non-director, click below.
        </p>
      </template>
      <BackButton :backButtonEmits="true" @click="$emit('back')" />
      <button class="ui button btn-primary" @click="openForm()">Add name</button>
    </template>
    <template v-else>
      <Form
        :form="form"
        :backButtonEmits="true"
        :loading="loading"
        @back-click="closeForm"
        @submit="submit"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'
import BackButton from '@/components/BackBtn'
import Form from '@product/features/products/components/Form'
import DirectorInformationForm from './forms/director-information-form'
import mapsFields from '@/mixins/mapsFields'
import notifies from '@/mixins/notifies'
import { getCompanyOwners, patchCompanyOwners } from '@/api/company'

export default {
  name: 'DirectorInformation',
  components: {
    Form,
    BackButton
  },
  mixins: [mapsFields, notifies],
  props: {
    directors: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      currentDirector: null,
      loading: false,
      formOpen: false,
      form: DirectorInformationForm
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID
    })
  },
  methods: {
    openForm(director) {
      if (director) {
        this.mapFields(director)
        this.currentDirector = director
      }
      this.formOpen = true
    },
    closeForm() {
      this.formOpen = false
      this.currentDirector = null
      this.$emit('scroll-to-top')
    },
    submit(data) {
      this.loading = true
      data.companyId = this.companyId
      const newDirectorData = [...this.directors]
      if (this.currentDirector) {
        const foundIndex = this.directors.findIndex(director => director.ownerId === this.currentDirector.ownerId)
        if (foundIndex !== -1) {
          newDirectorData[foundIndex] = data
        }
      } else {
        newDirectorData.push(data)
      }
      patchCompanyOwners({
        companyId: this.companyId,
        owners: newDirectorData
      }).then(() => {
        getCompanyOwners(this.companyId).then(({ data }) => {
          this.$emit('directors-updated', data)
        }).then(() => {
          this.closeForm()
          this.loading = false
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.director {
  border: 1px solid var(--color-secondary-50);
  padding: 14px;
  border-radius: 8px;
  cursor: pointer;
  margin: 4px 0;
}
</style>
