<template>
  <div class="component-wrapper">
    <h1>Supporting documents</h1>
    <p>These documents will allow Swoop to confirm your loan match and will speed up the application process.</p>
    <ProductRequiredDocuments
      class="required-documents"
      :requiredDocumentsList="requiredDocuments"
      v-bind="{ documents }"
      @change="documentsListChangeHandler"
      @click:checkbox="requiredDocumentsListCheckboxClickHandler"
    />
    <BackButton :backButtonEmits="true" @click="$emit('back')" />
  </div>
</template>

<script>
import ProductRequiredDocuments from '@/components/ProductRequiredDocuments'
import { getDocumentsList } from '@/api/document'
import BackButton from '@/components/BackBtn'

export default {
  name: 'SupportingDocuments',
  components: { ProductRequiredDocuments, BackButton },
  props: {
    companyId: {
      type: String,
      required: true
    },
    documents: {
      type: Array,
      default: () => []
    },
    requiredDocuments: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    documentsListChangeHandler() {
      getDocumentsList(this.companyId)
        .then(({ data }) => {
          this.$emit('documents-updated', data)
        })
    },
    requiredDocumentsListCheckboxClickHandler(doc) {
      const documentsCopy = [...this.documents]

      const index = documentsCopy.findIndex(d => d.title === doc.title && d.type === doc.type)
      if (index === -1 && doc.checked) {
        documentsCopy.push(doc)
      } else if (!doc.checked) {
        documentsCopy.splice(index, 1)
      }

      this.$emit('documents-updated', documentsCopy)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.required-documents {
  margin-top: 48px;
  /deep/ul {
    list-style: none;
    padding: 0;

    a.link {
      display: flex;
      text-decoration: none;
      justify-content: space-between;
      width: 100%;
      padding: 18px;
      border: 1px solid var(--color-secondary-50);
      border-radius: 8px;
      margin: 4px 0;
    }
  }
}
</style>
