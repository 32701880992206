<template>
  <div class="product-wrapper">
    <div class="ui active inverted dimmer page-loader" v-if="loading">
      <div class="ui medium text loader"><spinner :size="64" :loader="true" color /></div>
    </div>
    <template v-if="!currentStep">
      <h1>{{ title }}</h1>
      <ProductTile :product="product" :viewDetails="false" />
      <h3>Complete the sections below to securely submit your application to the Swoop Team.</h3>
      <div v-if="$config.whitelabel.features.illionEnabled">
        <IllionJourneySteps
          :company-id="companyId"
          :status="illionStatus"
          mode="product page"
          @illion-start-application="$emit('illion-start-application')"
          @close-overlay="closePageOverlay"
        />
        <h2>Product information</h2>
        <div>
          <p>{{getProductDescription()}}</p>
        </div>
      </div>
      <div v-else>
        <ProductSteps v-if="steps.length" :steps="steps" @select-step="selectStep" />
      <slot
        name="application-information"
      />
      </div>
    </template>
    <slot
      v-else
      name="step"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProductTile from './ProductTile'
import ProductSteps from './ProductSteps'
import Spinner from '@/components/Spinner'
import notifies from '@/mixins/notifies'
import IllionJourneySteps from '@/features/illion/IllionJourneySteps'
import { illionGetters } from '@/store/modules/illion/routines'

export default {
  name: 'Product',
  components: {
    Spinner,
    ProductTile,
    ProductSteps,
    IllionJourneySteps
  },
  mixins: [notifies],
  props: {
    title: {
      type: String,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    companyId: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    steps: {
      type: Array,
      default: () => []
    },
    currentStep: {
      type: Object,
      required: false
    },
    isDetailPage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      oppportunity: null,
      links: []
    }
  },
  methods: {
    selectStep(step) {
      this.$emit('select-step', step)
    },
    closePageOverlay() {
      this.$emit('close-overlay')
    },
    getProductDescription() {
      if (this.product !== null && this.product?.providerDescription) {
        return this.product.providerDescription
      }
      return 'No Description found'
    }
  },
  computed: {
    ...mapGetters({
      bankStatementsReport: illionGetters.BANK_STATEMENTS
    }),
    illionStatus() {
      let status = ''
      if (this.product.applicationStatusName) {
        status = 'applied'
      } else {
        if (!this.bankStatementsReport.length) {
          status = 'illion-start'
        } else {
          status = 'illion-end'
        }
      }
      return status
    }

  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

/deep/.btn-back {
  padding: 10px;
  width: 120px;
  border-radius: 30px;
  display: inline-flex;
  justify-content: center;
  margin-right: 12px;
}

.product-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  /deep/.product-tile{
    height: 100%;
    margin: 40px 0;
  }

  h1 {
    margin-bottom: 0;
  }

  h2 {
    font-size: 18px;
    font-weight: 700;
  }

  .product-disclaimer {
    font-size: 14px;
    font-weight: 700;
    margin: 30px 0;
  }

  .additional-information {
    font-size: 14px;
    background-color: var(--color-primary-500);
    color: $color-white;
    border-radius: 8px;
    padding: 10px;
    margin: 30px 0;
    a{
      color: $color-white;
    }
  }

  button.submit-application {
    height: 56px;
    width: 100%;
    margin-top: 40px;

    &.btn-disabled {
      color: var(--color-primary-300);
      border: 1px solid var(--color-primary-300);
      background-color: transparent;
      cursor: not-allowed;
    }
  }

  p.credit-rating {
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 40px;
    text-align: right;
  }

  .link {
    padding: 0px;
    cursor: pointer;
  }
}
</style>
