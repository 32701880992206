<template>
  <ProductListLocked
    v-if="locked"
    lockedTitle="Unlock Loans"
    lockedSubtitle="Find loan funding in less than 5 minutes"
    icon1="loans"
    lockedRouteName="goals"
    filterGoalsByType="loan"
    :creditScore="false"
  />
  <div v-else>
    <div class="loan-container">
      <LoanMatchesHeader />
      <LoanTabs
        :getQueryParamsForTesting="getQueryParamsForTesting"
        :getLoanInsights="getLoanInsights"
        @refetch-products="refetchProducts"
        :loading="loading"
        :sortedProducts="sortedProducts"
        :scroll="scroll"
        :fundingAmount="fundingAmount"
        :products="products"
      >
        <template v-slot:loan-form>
          <EnquiryForm v-if="isPageOverlayOpen" @refetch-products="refetchProducts" :getLoanInsights="getLoanInsights" :scroll="scroll" />
        </template>
      </LoanTabs>
      <LoanMatchesFilterBar
        :productSubcategories="productSubcategories"
        :filteredProducts="filteredProducts"
        :sortedProducts="sortedProducts"
        @refetch-selectedSubcategory="selectedSubcategoryTitle"
        @refetch-sortedProducts="updateSlicedProducts"
        :hideProductListContainer="hideProductListContainer"
        :selectedSubcategory="selectedSubcategory"
      />

      <!-- Original product page layout -->
      <div v-if="!getQueryParamsForTesting">
        <div class="total-products">{{ productCount }} results</div>
        <ProductList
          :products="slicedProducts"
          :locked="locked"
          :seeMore="productCount > 3"
          :company-id="companyId"
          @see-more="toggleSeeMore"
          :close-overlay-flag="closePageOverlayFlag"
          :loading="loading"
          :insights="insights"
          @refetch-products="refetchProducts"
        >
          <template v-slot:product-list-empty>
            <ProductListEmpty />
          </template>
          <template v-slot:product-detail="{ product }">
            <ProductDetail :title="'Your Loan Application'" :product="product" :company-id="companyId" @close-overlay="closePageOverlay" />
          </template>
        </ProductList>
      </div>

      <!-- groupedMatches layout -->
      <div v-else v-for="productSubcategory in productSubcategories" :key="productSubcategory" @click="selectedSubcategoryTitle(productSubcategory)">
        <ProductList
          :products="slicedGroup(productSubcategory)"
          :company-id="companyId"
          :locked="locked"
          :title="productSubcategory"
          @see-more="toggleSeeMore"
          :close-overlay-flag="closePageOverlayFlag"
          :productSubcategory="productSubcategory"
          :selectedSubcategory="selectedSubcategory"
          :hideProductListContainer="hideProductListContainer"
          :seeMore="sortedProducts.length > 3"
          :getQueryParamsForTesting="getQueryParamsForTesting"
        >
          <template v-slot:product-list-empty>
            <ProductListEmpty />
          </template>
          <template v-slot:product-detail="{ product }">
            <ProductDetail :title="'Your Loan Application'" :product="product" :company-id="companyId" @close-overlay="closePageOverlay" />
          </template>
        </ProductList>
      </div>
      <AggregatorProducts />
      <FundingJourneyModal data-testid="funding-modal" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'
import AggregatorProducts from '@product/components/aggregator/AggregatorProducts'
import ProductList from '@product/components/products/ProductList'
import ProductListLocked from '@product/components/products/ProductListLocked'
import ProductListEmpty from '@product/components/products/ProductListEmpty'
import ProductDetail from '@product/components/products/ProductDetail'
import FundingJourneyModal from './FundingJourneyModal'
import deepLinking from '@/mixins/deepLinking'
import { illionGetters, illionIframeRoutine, illionBankstatementsRoutine } from '@/store/modules/illion/routines'
import { swoopAssistantRoutine } from '@/store/modules/swoopAssistant/routines'
import { getIframeUrl, getBankStatements } from '@/api/illion'
import illionAllowedTenants from '@/mixins/illionAllowedTenants'
import config from '@/config/global'
import { getInsights } from '@product/api/opportunity'
import LoanMatchesHeader from '@product/components/loanMatchesHeader'
import LoanMatchesFilterBar from '@product/features/products/loan/loanMatchesFilterBar'
import convertToCamelCase from '@product/features/products/mixins/convertToCamelCase'
import LoanTabs from '@product/features/products/loan/loanTabs'
import { dynamicFormListGetters } from '@/store/modules/dynamicFormList/routines'
import EnquiryForm from '../../goals/components/EnquiryForm'
import { pageOverlayGetters } from '@/store/modules/pageOverlay/routines'
import { currentFormNameRoutine, fundingAmountRoutine, currentFormNameGetters } from '@/store/modules/currentFormDetails/routines'
import { getFormStructure } from '@/api/goals/index'

export default {
  name: 'LoanMatches',
  components: {
    ProductDetail,
    ProductList,
    ProductListEmpty,
    ProductListLocked,
    AggregatorProducts,
    FundingJourneyModal,
    LoanMatchesHeader,
    LoanMatchesFilterBar,
    LoanTabs,
    EnquiryForm
  },
  mixins: [illionAllowedTenants, deepLinking, convertToCamelCase],
  props: {
    locked: {
      type: Boolean,
      default: true
    },
    products: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedSubcategory: 'all',
      selectedStatus: 'all',
      closePageOverlayFlag: false,
      sortedProducts: this.products || [],
      hideProductListContainer: false,
      isButtonActive: '',
      insights: [],
      seeMore: false,
      loading: false,
      overlayOpen: false,
      fundingAmount: null
    }
  },
  mounted() {
    if (this.isAllowedTenant(this.tenant)) {
      getIframeUrl(this.companyId)
        .then(res => {
          this.setIllionIframeUrl(res.data)
        })
        .catch(e => console.error('Error when fetching iFrame url:', e))

      getBankStatements(this.companyId)
        .then(res => {
          this.setIllionBankStatements(res.data)
          this.decideOpenModal()
        })
        .catch(e => console.error('Error when fetching Bankstatements:', e))
    }
    this.getFundingAmount()
    this.checkForDeepLinkShift()
  },
  methods: {
    ...mapActions({
      setIllionIframeUrl: illionIframeRoutine.TRIGGER,
      setIllionBankStatements: illionBankstatementsRoutine.TRIGGER,
      setSwoopAssistant: swoopAssistantRoutine.TRIGGER,
      setCurrentFormName: currentFormNameRoutine.TRIGGER,
      setFundingAmountFieldValue: fundingAmountRoutine.TRIGGER
    }),
    scroll(side) {
      const scroll = document.querySelectorAll('.scrollable')

      if (side === 'left') {
        scroll[0].scrollTo({
          left: -2000,
          behavior: 'smooth'
        })
      } else {
        scroll[0].scrollTo({
          left: +2000,
          behavior: 'smooth'
        })
      }
    },
    closeOverlay() {
      this.overlayOpen = false
    },
    getLoanInsights(name) {
      getInsights(this.companyId, name)
        .then(response => {
          this.insights = response.data
        })
        .catch(e => console.error(e))
    },
    toggleSeeMore() {
      this.seeMore = !this.seeMore
    },
    decideOpenModal() {
      if (!this.bankStatementsReport?.length && !localStorage.getItem('hasHiddenLoanFunding')) {
        this.$modal.show('fundingJourneyModal')
      }
    },
    closePageOverlay() {
      this.closePageOverlayFlag = true
      this.$emit('close-overlay')
    },
    getBankOrAccountancyText() {
      if (config.whitelabel.features.linkBankAccount && !config.whitelabel.features.linkAccountancy) return 'bank'
      if (!config.whitelabel.features.linkBankAccount && config.whitelabel.features.linkAccountancy) return 'accountancy software'
      return 'bank or accountancy software'
    },
    slicedGroup(category) {
      if (!this.seeMore && !this.hideProductListContainer) {
        return this.sortedProducts.filter(product => product.subcategoryName === category).slice(0, 2)
      }
      return this.sortedProducts.filter(product => product.subcategoryName === category)
    },
    selectedSubcategoryTitle(subcategory) {
      this.selectedSubcategory = subcategory
    },
    refetchProducts(value) {
      this.loading = false
      if (!value) return
      this.setCurrentFormName(value)
      this.seeMore = false
      this.$emit('refetch-products', value)
    },
    updateSlicedProducts(value) {
      this.sortedProducts = value
    },
    async getFundingAmount() {
      if (this.currentFormName) {
        const res = await getFormStructure(this.companyId, this.currentFormName)
        const fundingAmountFields = ['borrowAmount', 'fundingAmount'] // each form has different value we need to check them
        const getFundingAmountField = res?.data?.formSections?.map(section => section?.formFields.find(field => fundingAmountFields?.includes(field.name))).filter(field => field)
        this.fundingAmount = Number(getFundingAmountField?.[0].formFieldValue?.value)
        this.setFundingAmountFieldValue(this.fundingAmount)
      }
    }
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID,
      iframeUrl: illionGetters.IFRAME_URL,
      bankStatementsReport: illionGetters.BANK_STATEMENTS,
      dynamicFormList: dynamicFormListGetters.DYNAMIC_FORM_LIST,
      currentFormName: currentFormNameGetters.CURRENT_FORM_NAME,
      isPageOverlayOpen: pageOverlayGetters.IS_PAGE_OVERLAY_OPEN
    }),
    redirect() {
      if (this.currentFormName) {
        const goal = this.dynamicFormList?.find(goal => goal.name === this.currentFormName)
        if (goal?.route) return { name: 'goals-form', params: { formRoute: goal?.route } }
      }
      return { name: 'goals', query: { type: 'loan' } }
    },
    slicedProducts() {
      if (!this.seeMore) {
        return this.filteredProducts.slice(0, 3)
      }
      return this.filteredProducts
    },
    productCount() {
      return this.filteredProducts.length
    },
    detectMobile() {
      return window.innerWidth <= 800
    },
    tenant() {
      return config.whitelabel.country.defaultCountry || 'United Kingdom'
    },
    filteredProducts() {
      return this.sortedProducts.filter(product => ['all', product.subcategoryName].includes(this.selectedSubcategory))
    },
    productSubcategories() {
      return [...new Set(this.sortedProducts.filter(product => product?.type?.toLowerCase() === 'loan').map(product => product.subcategoryName))]
    },
    getQueryParamsForTesting() {
      // test group loan versions for Optimizer
      return this.$route.query.groupedMatches
    }
  },
  watch: {
    async products(value) {
      this.loading = true
      if (this.currentFormName) {
        await this.getFundingAmount()
        await new Promise(resolve => setTimeout(resolve, 1000)) // for tabs after getting recommendation result we need to wait a sec then call insights on the matches page
        await this.getLoanInsights(this.currentFormName)
      }
      this.sortedProducts = value
    },
    insights(value) {
      value && (this.loading = false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.loan-container {
  max-width: 1100px;
  padding: 0 30px 30px 30px;

  .total-products {
    font-weight: bold;
    margin: 20px 0;
  }
}

@media only screen and (max-width: $breakpoint-sm-max) {
  .loan-container {
    padding: 0px 0px;
  }
}
</style>
