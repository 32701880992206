<template>
  <div class="locked-wrapper">
    <AggregatorLockedTile />
    <div class="locked-card">
      <div class="left-align">
        <h1>{{ error ? errorCardTitle : emptyCardTitle }}</h1>
        <p>{{ error ? errorCardText : emptyCardSubtitle }}</p>
        <p>{{ error ? '' : emptyCardText }}</p>
        <button class="btn btn-primary" @click="clickCreditReport()" v-if="!error">See your Credit Score</button>
        <button class="btn btn-primary" @click="clickKnowledgeCentre()" v-if="!error">Visit Knowledge Hub</button>
      </div>
    </div>
  </div>
</template>

<script>
import { amplitudeEvents } from '@/utils/constants'
import AggregatorLockedTile from './AggregatorLockedTile'
export default {
  components: { AggregatorLockedTile },
  data() {
    return {
      emptyCardTitle: 'Unfortunately, we haven’t got any instant offers for you right now.',
      emptyCardSubtitle: 'Don’t worry, try our other loan matches above.',
      emptyCardText: 'If you’re still struggling to find a match, try discovering your credit score, or visiting our Knowledge Hub for tips improving your eligibility.',
      errorCardTitle: 'Oops, something went wrong!',
      errorCardText: 'Please refresh the page or try again later'
    }
  },
  props: {
    error: {
      type: [Boolean, Error],
      default: false
    }
  },
  methods: {
    clickKnowledgeCentre() {
      window.open('https://swoopfunding.com/knowledge-hub/', '_blank')
      this.$ma.trackEvent({
        eventType: amplitudeEvents.AggregatorApi.CLICK_TO_KNOWLEDGE_BASE
      })
    },
    clickCreditReport() {
      this.$router.push({ name: 'credit-health-check' })
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/swoop/_variables';

.locked-wrapper {
  position: relative;
  padding: 0;
  .locked-card {
    width: 500px;
    padding: 24px;
    height: 300px;
    position: absolute;
    background-color: $color-white;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 8px;
    border: 1px solid var(--color-primary-100);
    text-align: center;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.04), 0 8px 16px 0 rgba(0, 0, 0, 0.06);
    @media only screen and (max-width: $breakpoint-md-max) {
      width: 100%;
      bottom: auto;
      height: auto;
      padding: 20px;
    }
    .left-align {
      text-align: left;
      p {
        margin: 20px 0;
        font-size: 15px;
      }
      .btn-primary {
        cursor: pointer;
        margin-right: 30px;
        @media only screen and (max-width: $breakpoint-md-max) {
          width: 200px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>
