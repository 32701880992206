<template>
  <div class="product-wrapper">
    <h1 class="product-title">{{ headerCopy }}</h1>
    <ProductTile :product="product" :viewDetails="false" />
    <div class="steps">
      <div class="step">
        <span class="step-number">1</span>
        You've provided company details
        <span class="icon material-icons">check</span>
      </div>
      <div class="step" :class="{ 'step-disabled': product.type === 'mir' }">
        <span class="step-number">2</span>
        You've provided director(s) information
        <span class="icon material-icons">check</span>
      </div>
      <div class="step" :class="{ 'step-disabled': product.type === 'mir' }">
        <span class="step-number">3</span>
        You've received an offer
        <span class="icon material-icons">check</span>
      </div>
    </div>
    <div>
      <p class="additional-information" v-if="product.type === 'mir'">
        The data points above are representative, and do not constitute an offer. <br />
        <span class="bold">Our Swoop support team will be in touch shortly to help collect and submit the additional information needed to get the full offer.</span>
      </p>
      <div class="product-disclaimer" v-else>
        <p class="bold">Next steps</p>
        <p>
          Your information has been shared with {{ product.providerName }} and they've come back with the above offer. This offer is subject to additional information; All you'll
          need to do is confirm a few final details, provide your financials or connect open banking, and they'll provide you with their final offer.
        </p>
        <p>
          To complete this application, you'll be transferred to {{ product.providerName }}'s website. Don’t worry, we’ve saved your details and will share them with
          {{ product.providerName }}. They'll contact you to help progress your offer.
        </p>
      </div>
    </div>
    <button
      v-if="product.applicationLink"
      @click="complete"
      @keydown.enter="complete"
      class="btn btn-primary complete-application"
      :disabled="buttonDisabled">
      Select offer with {{ product.providerName }} <spinner v-if="loading" />
    </button>
    <IwocaProductInformation v-if="product.providerName === 'iwoca'" :product="product" />
    <YoulendProductInformation v-if="product.providerName === 'YouLend'" :product="product" />
    <FibrProductInformation v-if="product.providerName === 'FIBR'" :product="product" />
    <RecapProductInformation v-if="product.providerName === 'Recap'" :product="product" />
  </div>
</template>

<script>
import { amplitudeEvents } from '@/utils/constants'
import ProductTile from '@product/components/products/ProductTile'
import { swoopAPI } from '@/api/'
import { getUserEvents } from '@product/api/aggregator'
import notifies from '@/mixins/notifies'
import IwocaProductInformation from './IwocaProductInformation.vue'
import YoulendProductInformation from './YoulendProductInformation.vue'
import FibrProductInformation from './FibrProductInformation.vue'
import RecapProductInformation from './RecapProductInformation.vue'

export default {
  components: {
    ProductTile,
    IwocaProductInformation,
    YoulendProductInformation,
    FibrProductInformation,
    RecapProductInformation
  },
  mixins: [notifies],
  props: {
    product: {
      type: Object,
      required: true
    },
    isDetailPage: {
      type: Boolean,
      default: false
    },
    companyId: {
      type: String
    },
    currentOpportunityId: {
      type: String
    }
  },
  data() {
    return {
      loading: false,
      links: []
    }
  },
  methods: {
    complete() {
      this.$ma.trackEvent({
        eventType: amplitudeEvents.AggregatorApi.COMPLETE_CTA,
        eventProperties: { product: this.product.providerName }
      })
      if (this.product?.applicationLink?.method && this.product?.applicationLink?.rel === 'apply' && !this.links?.length) {
        this.loading = true
        swoopAPI({
          method: this.product.applicationLink.method,
          url: this.product.applicationLink.href
        })
          .then(response => {
            this.loading = false
            this.links = response.data.links
            response.data.links.forEach(link => {
              if (link.method === 'GET' && link.rel === 'continue-with-third-party') {
                getUserEvents(this.companyId, this.currentOpportunityId, this.product.applicationId).then(() => {
                  this.$ma.trackEvent({
                    eventType: amplitudeEvents.AggregatorApi.USER_CLICK_ACCEPT,
                    userEvent: true
                  })
                })
                window.open(link.href, '_blank')
              }
            })
          })
          .catch(() => {
            this.loading = false
            this.notifyError()
          })
      } else if (this.product?.applicationLink?.method && this.product?.applicationLink?.rel === 'apply' && this.links?.length) {
        this.links.forEach(link => {
          if (link.method === 'GET' && link.rel === 'continue-with-third-party') {
            window.open(link.href, '_blank')
          }
        })
      } else if (this.product?.applicationLink?.method && this.product?.applicationLink?.rel === 'continue-with-third-party') {
        window.open(this.product.applicationLink.href, '_blank')
      }
    }
  },
  computed: {
    headerCopy() {
      switch (this.product.providerName) {
        case 'iwoca':
          return 'iwoca Flexible Credit Facility'
        case 'YouLend':
          return 'YouLend Merchant Cash Advance'
        case 'Fibr':
          return 'Revolving Credit Facility'
        default:
          return 'Instant Loan Offer'
      }
    },
    buttonDisabled(product) {
      const applicationStatuses = ['AwaitingDocuments', 'Offer', 'ClosedLost']
      const appStatus = applicationStatuses.includes(this.product.status)
      return !!(this.loading || product.type === 'mir' || appStatus)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

/deep/ .product-tile {
  height: 100%;
  margin-top: 20px;
}

.product-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  padding: 20px;

  .product-disclaimer {
    font-size: 14px;
    margin: 30px 0;
  }

  .additional-information {
    font-size: 14px;
    background-color: var(--color-primary-500);
    color: $color-white;
    border-radius: 8px;
    padding: 10px;
    margin: 30px 0;
    a {
      color: $color-white;
    }
  }

  .steps {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 850px) {
      flex-direction: column;
    }

    .step {
      position: relative;
      width: 30%;
      height: 54px;
      background-color: var(--color-secondary-500);
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color-white;
      font-size: 14px;
      line-height: 18px;
      text-align: left;
      padding: 0 54px;

      @media only screen and (max-width: 1100px) {
        font-size: 12px;
      }

      @media only screen and (max-width: 850px) {
        width: 100%;
        font-size: 14px;
        margin: 8px 0;
      }

      span.step-number {
        position: absolute;
        font-size: 24px;
        left: 20px;

        @media only screen and (max-width: 1300px) {
          font-size: 16px;
        }
      }

      span.material-icons {
        border: 1px solid $color-white;
        width: 35px;
        height: 35px;
        border-radius: 18px;
        display: flex;
        justify-content: center;
        font-size: 24px;
        right: 20px;
        position: absolute;
        align-items: center;

        @media only screen and (max-width: 1300px) {
          font-size: 16px;
          width: 20px;
          height: 20px;
        }
      }
    }
    .step-disabled {
      background-color: var(--color-neutral-300);
    }
  }

  .complete-application {
    height: 56px;
    margin-bottom: 32px;
  }

  /deep/.link {
    padding: 0px;
    cursor: pointer;
  }

  .btn-hidden{
    display: none;
  }
}
</style>
