<template>
  <div class="tab-container">
    <div class="tab">
      <em class="material-icons arrow prev" @click="scroll('left')">keyboard_arrow_left_icon</em>
      <div class="scrollable">
        <span class="group-product-tabs" v-for="loan in sortedLoanForms" :key="loan.displayName">
          <button :class="[{ active: activeTab(loan) }, 'btn btn-link']" @click="selectLoan(loan)" :data-cy="loan.displayName">
            {{ loan.displayName }}<em v-if="isFormFilled(loan)" class="material-icons checked"> check_circle </em>
          </button>
        </span>
      </div>
      <em class="material-icons arrow next" @click="scroll('right')">keyboard_arrow_right_icon</em>
    </div>
    <FormOverlay>
      <slot name="loan-form" v-if="selectedLoan || lastFilledFormName" />
    </FormOverlay>

    <div v-if="this.fundingAmount >= 10000 && this.products.length">
      <MatchingPageBanner />
    </div>
    <div v-if="selectedLoan || lastFilledFormName" class="tab-header" :data-cy="selectedLoan || lastFilledFormName">
      <div>
        <h1 class="product-title">{{ selectedLoan || lastFilledFormName }}</h1>
        <div class="matches-subtitle">From our funder network</div>
      </div>
      <button class="white-btn edit-icon" @click="openOverlayForm(selectedLoan)">View and edit questions <em class="material-icons">edit_note</em></button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { checkDynamicFormListUpdatedRoutine, dynamicFormListGetters } from '@/store/modules/dynamicFormList/routines'
import FormOverlay from '@/components/FormOverlay.vue'
import { pageOverlayGetters, pageOverlayRoutine } from '@/store/modules/pageOverlay/routines'
import { sortLoanFormsByMostRecentFilled } from '@/utils/sorting'
import { currentFormNameRoutine, currentFormNameGetters } from '@/store/modules/currentFormDetails/routines'
import { amplitudeEvents } from '@/utils/constants'
import { authGetters, userRoutine } from '@/store/modules/auth/routines'
import MatchingPageBanner from '@/components/MatchingPageBanner'

export default {
  name: 'LoanTabs',
  components: { FormOverlay, MatchingPageBanner },
  props: {
    getLoanInsights: {
      type: Function
    },
    scroll: {
      type: Function
    },
    fundingAmount: {
      type: Number
    },
    products: {
      type: Array
    }
  },
  data() {
    return {
      selectedLoan: null,
      loanForms: [],
      lastFilledFormName: null,
      lastFormDetails: null,
      lastFilledFormDetails: null
    }
  },
  async created() {
    await this.checkDynamicFormsListUpdated()
    await this.requestUser()
    this.getLoanForms()
  },
  methods: {
    ...mapActions({
      checkDynamicFormsListUpdated: checkDynamicFormListUpdatedRoutine.TRIGGER,
      setPageOverlay: pageOverlayRoutine.TRIGGER,
      setCurrentFormName: currentFormNameRoutine.TRIGGER,
      requestUser: userRoutine.TRIGGER,
    }),
    async getLoanForms() {
      if (this.dynamicFormList?.length) {
        this.loanForms = this.dynamicFormList?.filter(loan => loan.type === 'Loan' && loan.name !== 'loanJourney') // The old registrations were migrated to this LoanJourney, so we don't want to display.
        this.filledForm()
      }
    },
    isFormFilled(form) {
      return this.filledForms.find(filled => filled.formId === form.formId)
    },
    filledForm() {
      if (!this.filledForms || this.filledForms.length === 0) {
        // this means the user hasn't gone through any loan journey (eg: selected find grant)
        // per discussion on PRD-693, we will show the user Business Expansion as default
        this.lastFilledFormDetails = this.loanForms.find(loan => loan.name === 'businessExpansion')
      } else {
        this.lastFilledFormDetails = this.sortedLoanForms[0]
      }
      this.lastFilledFormName = this.lastFilledFormDetails?.displayName // needs to set form name on the tabs
      if (this.currentFormName && !this.currentFormNameNotLoan) {
        const currentFormData = this.sortedLoanForms.find(form => form.name === this.currentFormName)
        this.setCurrentFormName(currentFormData?.name)
        this.selectLoan(currentFormData)
      } else {
        this.setCurrentFormName(this.lastFilledFormDetails?.name)
        this.selectLoan(this.lastFilledFormDetails)
      }
    },
    openOverlayForm() {
      this.$ma.trackEvent({ eventType: amplitudeEvents.Matches.VIEW_AND_EDIT_LOAN_FORM })
      this.setPageOverlay(!this.isPageOverlayOpen)
    },
    activeTab(loan) {
      if (this.selectedLoan) {
        return loan.displayName === this.selectedLoan
      } else {
        return this.lastFilledFormDetails?.displayName === loan.displayName
      }
    },
    selectLoan(loan) {
      if (!loan) return
      this.selectedLoan = loan.displayName // selected form name on the tabs
      const selectedFormData = this.sortedLoanForms.find(form => form.displayName?.toLowerCase() === this.selectedLoan?.toLowerCase())
      this.setCurrentFormName(selectedFormData?.name)
      this.$emit('refetch-products', selectedFormData?.name)
    }
  },
  computed: {
    ...mapGetters({
      user: authGetters.USER,
      dynamicFormList: dynamicFormListGetters.DYNAMIC_FORM_LIST,
      isPageOverlayOpen: pageOverlayGetters.IS_PAGE_OVERLAY_OPEN,
      currentFormName: currentFormNameGetters.CURRENT_FORM_NAME
    }),
    filledForms() {
      return this.user?.goalsCompleted?.filter(form => form.type === 'Loan')
    },
    sortedLoanForms() {
      return sortLoanFormsByMostRecentFilled(this.filledForms, this.loanForms)
    },
    currentFormNameNotLoan(){
      return ['findGrant','findInvestor'].includes(this.currentFormName)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';
@import '@/assets/styles/swoop/buttons.scss';

.tab-container {
  width: 100%;

  .tab {
    margin-top: 20px;
    display: flex;
    align-items: center;
    overflow: hidden;

    .scrollable {
      display: flex;
      overflow-y: scroll;
      white-space: nowrap;

      &::-webkit-scrollbar {
        // hide scroll
        width: 0px;
      }
      .group-product-tabs {
        margin-top: 10px;
        font-weight: bold;
        font-size: 25px;
        border-bottom: 1px solid var(--color-neutral-200);
        padding: 0 12px;

        .btn-link {
          font-size: 13px;
          font-weight: bold;
          color: var(--color-primary-300);
          text-decoration: none;
          padding: 0 5px;
          border-radius: 0;

          &:hover {
            color: $color-primary;
          }

          &:active,
          &.active {
            color: $color-primary;
            border-bottom: 3px solid var(--color-secondary-500);
          }
        }
      }
    }

    .arrow {
      width: 30px;
      vertical-align: middle;
      cursor: pointer;
      border: 1px solid var(--color-secondary-50);
      border-radius: 8px;
      background-color: $color-white;
      box-shadow: 0px 0px 1px rgba(0, 62, 82, 0.16), 0px 1px 3px rgba(0, 62, 82, 0.16);
    }
    .checked {
      padding-left: 5px;
      font-size: 15px;
      background: transparent;
      border: none;
      vertical-align: middle;
    }
  }

  .tab-header {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: $breakpoint-sm-max) {
      flex-direction: column;
      margin-bottom: 20px;
      align-items: flex-start;
    }

    .edit-icon {
      height: 35px;
      white-space: nowrap;

      .material-icons {
        margin: 0 5px;
        font-size: 15px;
        vertical-align: middle;
      }
    }

    h1.product-title {
      color: var(--color-primary-500);
      font-size: 22px;
      font-weight: 500;
      margin-bottom: 0;

      &:before {
        content: '';
        border-left: 3px solid var(--color-secondary-500);
        margin-right: 8px;
      }
    }
    .subtitle {
      margin-bottom: 20px;
    }

    /deep/ h1 {
      margin: 0;
    }
  }
  .hidden {
    display: none;
  }
  .visible {
    display: block;
  }
}
</style>
