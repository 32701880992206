<template>
  <div class="contact-banner-container" v-if="showContactBanner">
    <img :src="icon || require('../assets/images/profilePlaceholder.png')" class="contact-banner-image"/>
    <div class="contact-details">
      <span>{{actionText}}</span>
      <span>{{name}}</span>
    </div>
    <a :href="`tel:${phoneNumber}`"><span class="icon material-icons" v-if="phoneNumber">phone</span></a>
    <a :href="`mailto:${email}`" target="_blank" v-if="email">
      <span class="icon material-icons">email</span>
    </a>
  </div>
</template>

<script>
export default {
  name: 'ContactBanner',
  props: {
    icon: {
      required: false,
      default: ''
    },
    actionText: {
      required: false,
      default: ''
    },
    name: {
      required: false,
      default: ''
    },
    phoneNumber: {
      required: false,
      default: ''
    },
    email: {
      required: false,
      default: ''
    }
  },
  computed: {
    showContactBanner() {
      return this.$config.whitelabel.components.matchesContactBannerEnabled && (this.phoneNumber || this.email)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.contact-banner-container {
  display: flex;
  align-content: center;
  align-items: center;
  flex-wrap: nowrap;
  height: 50px;
  min-width: fit-content;
  gap: 10px;

  .contact-banner-image {
    border-radius: 100%;
    height: 60px;
    max-height: 60px;
  }

  .contact-details {
    display: flex;
    flex-direction: column;

    span {
      line-height: 16px;
      font-size: 14px;
    }

    :nth-child(2) {
      font-weight: 600;
    }
  }

  .icon {
    background-color: white;
    color: var(--color-primary-300);
    border-radius: 50%;
    padding: 3px;
    box-shadow: 0px 1px 4px 0px rgb(219, 219, 219);

    &:hover {
      transform: scale(1.1);
      cursor: pointer;
    }
  }

  @media only screen and (max-width: $breakpoint-xs-max) {
    margin: 10px 0;
  }
}
</style>
