<template>
  <Product
    :title="title"
    :product="product"
    :loading="loading"
    :steps="stepsRequired"
    :currentStep="currentStep"
    :company-id="companyId"
    ref="product"
    @select-step="selectStep"
    @illion-start-application="submitForReview"
    @close-overlay="closePageOverlay"
  >
    <template v-slot:application-information>
      <button
        v-if="!applicationSubmitted && !product.applicationStatusName"
        @click="submitForReview"
        class="btn btn-primary submit-application"
        :class="{ 'btn-disabled': loading || !stepsComplete }"
        :disabled="loading || !stepsComplete"
      >
        Submit application<spinner v-if="loading" />
      </button>
      <button v-else @click="submitForReview" class="btn btn-disabled submit-application" :disabled="true">
        {{ product.applicationStatusName ? product.applicationStatusName : 'In Swoop Review' }}
      </button>
      <p class="credit-rating">Your credit rating will not be affected by proceeding with your Swoop review process.</p>
      <h2>Product information</h2>
      <div>{{ getOpportunityDescription }}></div>
    </template>
    <template v-slot:step>
      <DirectorInformation
        v-if="currentStep.name === 'director-information'"
        :product="product"
        :directors="directors"
        @directors-updated="updateDirectors"
        @scroll-to-top="scrollToTop"
        @back="selectStep(null)"
      />
      <SupportingDocuments
        v-if="currentStep.name === 'supporting-documents'"
        :companyId="companyId"
        :documents="documents"
        :requiredDocuments="requiredDocuments"
        :product="product"
        @documents-updated="updateDocuments"
        @back="selectStep(null)"
      />
    </template>
  </Product>
</template>

<script>
import { mapGetters } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'
import Product from '@product/components/products/Product.vue'
import DirectorInformation from '@product/components/products/steps/DirectorInformation'
import SupportingDocuments from '@product/components/products/steps/SupportingDocuments'
import { setOpportunityApplication } from '@/api/opportunityApplication'
import { getCompany, getCompanyOwners } from '@/api/company'
import { requiredDocumentsAdapter } from '@/utils/utils'
import { getFinancialData } from '@/api/financialData'
import { getOpportunity } from '@product/api/opportunity'
import { getDocumentsList } from '@/api/document'
import { amplitudeEvents } from '@/utils/constants'

export default {
  name: 'ProductDetail',
  components: {
    Product,
    DirectorInformation,
    SupportingDocuments
  },
  props: {
    title: {
      type: String,
      required: true
    },
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      currentStep: null,
      directors: [],
      documents: [],
      applicationSubmitted: false,
      company: null,
      opportunity: null,
      currentApplicationId: null,
      correlationId: null,
      eligibleStatus: null,
      financials: null,
      productSteps: [
        {
          label: 'You matched this offer',
          enabled: true,
          complete: true
        },
        {
          label: 'Add director information',
          name: 'director-information',
          editable: true,
          enabled: true,
          complete: false
        },
        {
          label: 'Add supporting documents',
          name: 'supporting-documents',
          editable: true,
          enabled: true,
          complete: false
        }
      ]
    }
  },
  mounted() {
    this.checkProductStatus()
  },
  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID
    }),
    stepsRequired() {
      return this.productSteps.filter(step => step.enabled)
    },
    stepsComplete() {
      return this.stepsRequired.filter(step => !step.complete).length === 0
    },
    requiredFinancialInformationComplete() {
      if (!this.financials) return false
      return Boolean(
        ((this.financials.isEstablishedLessOneYear && parseFloat(this.financials.estimatedTurnover) > 0) ||
          (!this.financials.isEstablishedLessOneYear && parseFloat(this.financials.turnoverLastYear) >= 0)) &&
          parseFloat(this.financials.currentCustomersNumber) >= 0 &&
          parseFloat(this.financials.monthlyBusinessDebtRepayments) >= 0 &&
          parseFloat(this.financials.existingBusinessOverdraft) >= 0 &&
          parseFloat(this.financials.totalBusinessBorrowingRepayments) >= 0
      )
    },
    requiredDocuments() {
      if (!this.opportunity || !this.opportunity.requiredDocuments) return []
      return requiredDocumentsAdapter({
        opportunity: this.opportunity,
        documentsList: this.documents,
        isPrivateLimitedCompany: this.isPrivateLimitedCompany,
        requiredDocuments: this.opportunity.requiredDocuments
      })
    },
    getOpportunityDescription() {
      if (this.opportunity !== null) {
        if (this.opportunity.detailedDescription) {
          return this.opportunity.detailedDescription
        }
        if (this.opportunity.briefProductDescription) {
          return this.opportunity.briefProductDescription
        }
      }
      if (this.product.briefProductDescription) {
        return this.product.briefProductDescription
      }
      return 'No Description found'
    }
  },
  watch: {
    requiredDocuments(requiredDocuments) {
      if (!requiredDocuments.filter(document => !document.isDocumentUploaded).length) {
        this.completeStep('supporting-documents')
      }
    },
    requiredFinancialInformationComplete(requiredFinancialInformationComplete) {
      if (requiredFinancialInformationComplete) {
        this.completeStep('financial-information')
      }
    }
  },
  methods: {
    selectStep(step) {
      if (!step) {
        this.checkProductStatus()
      }
      this.currentStep = step
    },
    scrollToTop() {
      if (this.$refs.product && this.$refs.product.$el) {
        setTimeout(() => {
          this.$refs.product.$el.scrollIntoView()
        }, 0)
      }
    },
    checkProductStatus() {
      this.loading = true
      Promise.all([
        getCompanyOwners(this.companyId).then(({ data }) => {
          if (data.length) {
            this.completeStep('director-information')
          }
          this.directors = data
        }),
        getCompany(this.companyId).then(({ data }) => {
          this.company = data
        }),
        getDocumentsList(this.companyId).then(({ data }) => {
          // keep previous state of checkbox type documents
          this.documents = [...data, ...this.documents.filter(doc => doc.isCheckBox)]
        }),
        getOpportunity(this.product.opportunityId).then(({ data }) => {
          this.opportunity = data
        }),
        getFinancialData(this.companyId).then(({ data }) => {
          this.financials = data
        })
      ]).finally(() => {
        this.loading = false
      })
    },
    isPrivateLimitedCompany() {
      return this.company && this.company.companyStructures === 'Private Limited Company'
    },
    completeStep(stepName) {
      const foundIndex = this.productSteps.findIndex(step => step.name === stepName)

      if (foundIndex !== -1) {
        this.productSteps[foundIndex].complete = true
      }
    },
    updateDirectors(directors) {
      this.directors = directors
    },
    updateDocuments(documents) {
      this.documents = documents
    },
    submitForReview() {
      return setOpportunityApplication({
        companyId: this.companyId,
        opportunityId: this.product.opportunityId,
        status: 'ReviewingYourDetails'
      })
        .then(() => {
          this.applicationSubmitted = true
          this.submitAnalytics()
        })
        .catch(error => {
          console.error(error)
        })
    },

    submitAnalytics() {
      /** Amplitude Analytics */
      this.$ma.addItem({
        'Fund App Total Counter': 1
      })
      this.$ma.setUserProperties({
        [`Fund App ${this.opportunity.type} Submitted`]: true
      })
      this.$ma.trackEvent({
        eventType: amplitudeEvents.fundingCheckout.APPLY_FOR_PRODUCT,
        eventProperties: {
          'Product ID': this.product.opportunityId,
          'Product Type': this.opportunity.type,
          'Product Name': this.opportunity.productName,
          'Product Vendor': this.opportunity.providerName
        }
      })
    },
    closePageOverlay() {
      this.$emit('close-overlay')
    }
  }
}
</script>
