<template>
  <div class="spinner-wrapper">
    <div class="spinner">
      <spinner :size="40" :loader="true" color />
    </div>
    <div>
      <div class="bold">Your offers are on their way!</div>
      <div>We're still actively finding you the best offers from our network.</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    locked: {
      type: Boolean,
      default: true
    },
    allProvidersResponded: {
      type: Boolean
    },
    emptyData: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.spinner-wrapper {
  border: 1px solid var(--color-secondary-500);
  background-color: $color-white;
  border-radius: 8px;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  border-left-width: thick;

  .spinner {
    margin-right: 20px;
  }
}
</style>
