<template>
  <div>
    <h1>Product information</h1>
    <p>
      The iwoca Flexi-loan is flexible business funding that gives you access to money, but you only pay for what you use. This means iwoca’s lending aligns perfectly with your
      business’ cashflow.
    </p>
    <p>
      Award-winning technology allows them to make a decision based on your business performance, not just a credit score. Apply in minutes and have the funds in your bank account
      within hours. iwoca move as fast as your business.
    </p>
    <p>You can find out more about unsecured loan products in our <button class="link" @click="clickKnowledgeCentre()">Knowledge Centre</button>.</p>
    <h3>How it works</h3>
    <h4>Your offer</h4>
    <p>
      iwoca funding is unsecured and can be used for any business purpose. iwoca have already seen enough information to make you an indicative offer. This offer is subject to
      further checks.
    </p>
    <h4>Accepting your quick offer</h4>
    <p>
      To accept the offer, you’ll continue to iwoca’s website where they’ll ask for any additional information they need - just fill out a few final details and upload the signed
      agreement. You can then transfer as much as you need to your bank account, and the funds will typically be in your account in hours.
    </p>
    <h4>Repaying and topping up</h4>
    <p>
      iwoca don't charge early repayment fees, they only charge interest for the days you have the money. If you need more funds, it’s easy to apply for a top up, and as your
      business grows your credit limit will too.
    </p>
  </div>
</template>

<script>
import { amplitudeEvents } from '@/utils/constants'

export default {
  props: {
    product: {
      type: Object
    }
  },
  methods: {
    clickKnowledgeCentre() {
      window.open('https://swoopfunding.com/merchant-cash-advance/', '_blank')
      this.$ma.trackEvent({
        eventType: amplitudeEvents.AggregatorApi.CLICK_TO_KNOWLEDGE_BASE,
        eventProperties: { product: this.product.providerName, type: this.product.type }
      })
    }
  }
}
</script>
