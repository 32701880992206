<template>
  <!-- // TODO: HF to ensure that modal close icon is not setting the hiddenflag -->
  <modal name="fundingJourneyModal" height="auto" width="860" classes="v--modal background-triangles">
    <div class="close-modal" @click="dismissModal()">
      <span class="material-icons">close</span>
    </div>
    <section v-if="modalStart" class="funding-journey-modal text-center" data-testid="modal-copy">
      <img src="@/assets/images/icons/icons_energy.svg" alt="Supercharge" width="auto" height="40" />
      <h2 class="modal-title">Supercharge your funding journey</h2>
      <div class="modal-text text-center">
        <p>We'll automatically collect 6 months bank statements from you right now, this significantly cuts application processing times</p>
      </div>
      <div class="modal-actions">
        <button class="btn btn-primary" type="button" @click="connectBankAccount()">
          <img class="btn-icon" src="@/assets/images/icons/icons_banking.svg" alt="Banking" width="auto" height="22" />
          Connect your bank account
        </button>
        <div class="dismiss-actions">
          <button class="btn btn-link" type="button" @click="dismissModal()">Not now</button>
          <button class="btn btn-link" type="button" @click="refuseModal()">Never show this again</button>
        </div>
      </div>
    </section>
    <IllionIFrame v-else mode="modal" data-testid="modal-iframe" origin="popup" @submit_all="setModalFlag" />
  </modal>
</template>

<script>
import IllionIFrame from '@/features/illion/IllionIFrame'
import illionAllowedTenants from '@/mixins/illionAllowedTenants'
import { amplitudeEvents } from '@/utils/constants'

export default {
  name: 'FundingJourneyModal',
  components: {
    IllionIFrame
  },
  mixins: [illionAllowedTenants],
  data() {
    return {
      amplitudeEvents,
      modalStart: true
    }
  },
  methods: {
    connectBankAccount() {
      if (process.env.VUE_APP_TENANT !== 'aus') {
        this.modalStart = false
        this.$ma.trackEvent({
          eventType: amplitudeEvents.Illion.START_APPLICATION,
          eventProperties: {
            'Event Origin': 'Popup'
          }
        })
        return
      }
      this.$router.push({ name: 'integrations' })
    },
    dismissModal() {
      this.$ma.trackEvent(amplitudeEvents.Illion.POPUP_NOT_NOW)
      this.$modal.hide('fundingJourneyModal')
    },
    refuseModal() {
      this.$ma.trackEvent(amplitudeEvents.Illion.POPUP_NEVER_AGAIN)
      this.$modal.hide('fundingJourneyModal')
      this.setModalFlag()
    },
    setModalFlag() {
      if (!localStorage.getItem('hasHiddenLoanFunding')) {
        localStorage.setItem('hasHiddenLoanFunding', true)
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/swoop/_variables';

.background-triangles {
  background-image: url('../../../../../src/assets/images/triangles.svg');
  background-repeat: no-repeat;
  background-position: left 50%;
}
.close-modal {
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 20px;
  line-height: 0;
  z-index: 10;
}

.funding-journey-modal {
  padding: 1rem;
  position: relative;

  .background-triangles {
    position: absolute;
  }
  .modal-title {
    font-size: 26px;
    font-weight: bold;
  }
  .modal-text {
    margin: 2rem 0;
  }
  .modal-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .dismiss-actions {
    display: flex;
    margin-top: 1rem;
  }
  .btn {
    display: flex;
    align-items: center;
    img {
      margin-right: 1rem;
    }
  }
  .btn-icon {
    filter: invert(1);
  }
}
</style>
